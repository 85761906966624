<template>
  <div>
    <v-container grid-list-lg>
      <h2 class="blue-grey--text nunito lighten-3"><b>Subscription</b></h2>
      <v-divider class="mb-2"></v-divider>
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(set, index) in settings.plan" :key="index">
          <v-expansion-panel-header>{{ set.name }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-for="(feat, i) in subs" :key="i">
              <v-col cols="10">
                <p class="text-capitalize">{{ feat }}</p>
              </v-col>
              <v-col cols="2">
                <v-switch
                  :value="feat"
                  v-model="set.features"
                  color="indigo"
                  class="mt-0"
                  dense
                  @change="updateSwitch"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Duration"
                  outlined
                  color="info"
                  :value="set.detail.duration"
                  @input="updateInput($event, index, 'duration')"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="type"
                  :value="set.detail.duration_type"
                  label="Duration Type"
                  color="indigos"
                  item-color="indigo"
                  @input="updateInput($event, index, 'duration_type')"
                ></v-select>
              </v-col>
            </v-row>
            <p>
              <b
                >* enter 'unlimited' in Duration field to make the plan does not
                have an expiry date</b
              >
            </p>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Price"
                  outlined
                  color="info"
                  :value="set.detail.price"
                  @input="updateInput($event, index, 'price')"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Transaction Fee"
                  outlined
                  color="info"
                  :value="set.service_charge"
                  @input="updateSC($event, index)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10">
                <p class="text-capitalize">Make default</p>
              </v-col>
              <v-col cols="2">
                <v-switch
                  v-model="set.default"
                  color="indigo"
                  class="mt-0"
                  dense
                  @change="updateSwitch"
                ></v-switch>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
    <v-container grid-list-xs fluid>
      <v-row>
        <v-col cols="10"></v-col>
        <v-col cols="2">
          <div class="flex justify-end mt-8">
            <v-btn color="success" @click="save" large>save</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Settings",
  data() {
    return {
      subs: [
        "dashboard",
        "tags",
        "products",
        "vouchers",
        "manage_order",
        "summary",
        "customers",
        "vendors",
        "inventory",
      ],
      type: ["unlimited", "month", "year"],
    };
  },

  computed: {
    ...mapGetters({
      settings: "getGlobalSettings",
    }),
  },

  methods: {
    updateInput(e, index, key) {
      this.settings.plan[index].detail[key] = e;
      this.$store.commit("updateGlobalSettings", this.settings);
    },
    updateSC(e, index) {
      this.settings.plan[index].service_charge = e;
      this.$store.commit("updateGlobalSettings", this.settings);
    },
    save() {
      let a = this.settings.plan.filter((d) => d.default == true);
      if (a.length == 0 || a.length > 1) {
        alert("Please select only one plan to make as default");
      } else {
        this.$store.dispatch("startLoad");
        this.$store.dispatch("saveGlobalSettings");
      }
    },
    updateSwitch() {
      this.$store.commit("updateGlobalSettings", this.settings);
    },
    // firstHalf(i) {
    //   const half = Math.ceil(i.length / 2);
    //   return i.splice(0, half);
    // },
    // secondHalf(i) {
    //   const half = Math.ceil(i.length / 2);
    //   return i.splice(-half);
    // },
  },

  beforeMount() {
    // this.$store.dispatch("startLoad");
    // this.$store.dispatch("fetchGlobal");
    // console.log("settings", this.settings);
  },
};
</script>
<style >
.v-text-field__details {
  display: none;
}
.v-messages {
  display: none;
}
</style>